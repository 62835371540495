import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="catalog--product-ia-modale"
export default class extends Controller {
  connect() {
    $.ajax({
      method: 'POST',
      dataType: 'json',
      url: '/back/toporder_prompt_entity/question',
      data: {
        aiQuestionType: $(this.element).data().questionType,
        productUniqueId: $('.edit_product_form').data().uniqueId
      },
    }).done((data) => {
      if($(this.element).data().questionType == 'AUTOMATIC_PRODUCT_DATA_NUTRI_SCORE') {
        $('#product-edit__ia-actions-container').addClass('product-edit__ia-actions-container--has-qr-code')
        $('#product-edit__ia-actions-container').get(0).controller.showQrCode()
        $('#modal_rails_5 .modal-footer').html(`
          <div class='w-100 d-flex justify-content-end' data-controller="catalog--product-ia-footer">
            <button type='button' class='btn new-btn new-btn-success' data-dismiss="modal" aria-label="Close">Valider <i class="fas fa-check"></i></button>
          </div>
        `)
      } else {
        $('.modal-footer .new-btn-success').attr('disabled', false)
        this.initial_content = data.answer
        console.log(data)
        $('#modal_rails_5 .modal-body').removeClass('p-0')
        $(this.element).removeClass('toporder-ia-modal-product--requesting-ia')
        $(this.element).html(`
          <div class='row h-100'>
            <div class='col-md-6 h-100'>
              <div class='toporder-ia-modal-product--result--side'>
                <p class='toporder-ia-modal-product--result--main-title'>Fiche produit actuelle</p>
                <legend class='legend-v2'>Description :</legend>
                <textarea class='toporder-ia-modal-product--result--content' readonly='true'>${$('#product_allergens').val()}</textarea>
                
                <div class='d-flex justify-content-between align-items-center'>
                  <legend class='legend-v2'>Prix de vente :</legend>
                  <p class='toporder-ia-modal-product--result--price'>${$('.ppu_price_input').eq(0).val()} € TTC</p>
                </div>
              </div>
            </div>
            <div class='col-md-6 h-100'>
              <div class='toporder-ia-modal-product--result--side'>
                <div class='toporder-ia-modal-product--suggestion-container'>
                  <p class='toporder-ia-modal-product--result--main-title'>Suggestion Toporder IA</p>
                  <p class='toporder-ia-modal-product--result--rollback' data-action='click->catalog--product-ia-modale#applyDefault'>
                    <span>Revenir à la suggestion</span>
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path id="Vector" d="M7.49935 11.6667L4.16602 8.33333L7.49935 5" stroke="#6B7281" stroke-width="1.45833" stroke-linecap="round" stroke-linejoin="round"/>
                      <path id="Vector_2" d="M4.16602 8.3335H13.3327C14.2167 8.3335 15.0646 8.68469 15.6897 9.30981C16.3148 9.93493 16.666 10.7828 16.666 11.6668C16.666 12.5509 16.3148 13.3987 15.6897 14.0239C15.0646 14.649 14.2167 15.0002 13.3327 15.0002H12.4993" stroke="#6B7281" stroke-width="1.45833" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                  </p>
                </div>
                
                <legend class='legend-v2'>Description :</legend>
                <textarea class='toporder-ia-modal-product--result--textarea'>${data.answer.description}</textarea>
                <div class='d-flex justify-content-between align-items-center'>
                  <legend class='legend-v2'>Prix de vente :</legend>

                  <div class="input-group input-group--v2">
                    <input type="numeric" class="form-control float" value='${data.answer.price}'>
                    <div class="input-group-append">
                      <span class="input-group-text">€ TTC</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        `)

      }

    })
  }

  applyDefault() {
    $(this.element).find('.toporder-ia-modal-product--result--textarea').val(this.initial_content.description)
    $(this.element).find('.toporder-ia-modal-product--result--side input').val(this.initial_content.price)

  }
}
