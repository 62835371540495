import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="catalog--product-ia-footer"
export default class extends Controller {
  applyModifications() {
    $('#product_allergens').val($('.toporder-ia-modal-product--result--textarea').val())
    $('.ppu_price_input').eq(0).val($('.toporder-ia-modal-product--result--side input').val())
    $('.ppu_price_input').eq(0).parents('.ppu_fields').get(0).controller.calc_reverse()
    $('.ppu_price_input').eq(0).parents('.ppu_fields').get(0).controller.update_price_others()
    $('#modal_rails_5').modal('hide')
  }

  regenerateQrCode() {
    $('#product-edit__ia-actions-container').get(0).controller.prepareQuestion('AUTOMATIC_PRODUCT_DATA_NUTRI_SCORE')
  }
}
